import "../Filter/Filter.css";
import { Dropdown } from "semantic-ui-react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import "semantic-ui-css/semantic.min.css";
import { BASE_URL } from "../../Utils/useApi";

const Index = ({ showBookAppointmentTab, filter_Class, _parentClass }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("tab1");
  const [selectedSpecialization, setSelectedSpecialization] = useState("");
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [specializations, setSpecializations] = useState([]);
  const [defaultDoctors, setDefaultDoctors] = useState([]);

  useEffect(() => {
    axios
      .get(
        `${BASE_URL}/doctor_filter.php`)
      .then((response) => {
        const specializationsData = response.data;
        setSpecializations(specializationsData);

        // Extract all doctors from the response
        const allDoctors = specializationsData.reduce((acc, spec) => {
          return acc.concat(spec.doctors);
        }, []);
        setDefaultDoctors([...new Set(allDoctors)]); // Remove duplicates

        // Extract specialization and doctor from URL and set them
        const queryParams = new URLSearchParams(location.search);
        const specialization = queryParams.get("s");
        const doctor = queryParams.get("d");

        if (specialization) {
          const decodedSpecialization = titleCase(
            specialization.replace(/-/g, " ")
          );
          setSelectedSpecialization(decodedSpecialization);
        }
        if (doctor) {
          const decodedDoctor = titleCase(doctor.replace(/-/g, " "));
          const matchingDoctor = allDoctors.find(
            (doc) => normalizeString(doc) === normalizeString(decodedDoctor)
          );
          setSelectedDoctor(matchingDoctor || decodedDoctor);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [location.search]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleSpecializationChange = (event, data) => {
    setSelectedSpecialization(data.value);
    setSelectedDoctor(""); // Reset the doctor selection
  };

  const handleDoctorChange = (event, data) => {
    setSelectedDoctor(data.value);
  };

  const handleFocusSpecialization = () => {
    setSelectedSpecialization("");
  };

  const handleFocusDoctor = () => {
    setSelectedDoctor("");
  };

  const specializationOptions = specializations.map((spec) => ({
    key: spec.name,
    text: spec.name,
    value: spec.name,
  }));

  const selectedSpecializationData = specializations.find(
    (spec) => spec.name === selectedSpecialization
  );
  const doctorOptions = selectedSpecializationData
    ? selectedSpecializationData.doctors.map((doc) => ({
      key: doc,
      text: doc,
      value: doc,
    }))
    : defaultDoctors.map((doc) => ({
      key: doc,
      text: doc,
      value: doc,
    }));

  const formatForUrl = (str) => {
    return str
      ? str
        .toLowerCase()
        .replace(/\s+/g, "-")
        .replace(/[^a-z0-9-\']/g, "") // Exclude the ' character from replacement
      : "";
  };

  const normalizeString = (str) => {
    return str
      .toLowerCase()
      .replace(/\s+/g, "")
      .replace(/[^a-z0-9]/g, ""); // Remove special characters
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const specialization = selectedSpecialization
      ? formatForUrl(selectedSpecialization)
      : "";
    const doctor = selectedDoctor ? formatForUrl(selectedDoctor) : "";
    const queryParams = new URLSearchParams();
    if (specialization) queryParams.append("s", specialization);
    if (doctor) queryParams.append("d", doctor);

    if (queryParams.toString()) {
      navigate(`/doctors?${queryParams.toString()}`);
    } else {
      alert("Please select at least a specialization or a doctor.");
    }
  };

  const titleCase = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  return (
    <div
      className={`app-WebContainer radius-50 ${_parentClass}`}
      data-aos="fade-up"
      data-aos-offset="10"
      data-aos-duration="600"
      data-aos-once="true"
      data-aos-easing="ease-in-sine"
    >
      <div className={`${filter_Class}`}>
        {showBookAppointmentTab && (
          <div className="tabs">
            <button
              onClick={() => handleTabClick("tab1")}
              className={activeTab === "tab1" ? "active" : ""}
            >
              Find a Doctor
            </button>

            <Link
              to="/book-an-appointment"
              onClick={() => handleTabClick("tab2")}
            >
              <button>Book an Appointment</button>
            </Link>
          </div>
        )}
        <div className="tab-content">
          {activeTab === "tab1" && (
            <form onSubmit={handleSubmit}>
              <div className="col-12">
                <div className="row">
                  <div className="col-lg-5 col-12">
                    <Dropdown
                      placeholder="Specialization"
                      fluid
                      search
                      selection
                      options={specializationOptions}
                      value={selectedSpecialization}
                      onChange={handleSpecializationChange}
                      onFocus={handleFocusSpecialization}
                    />
                  </div>
                  <div className="col-lg-5 col-12">
                    <Dropdown
                      placeholder="Doctor"
                      fluid
                      search
                      selection
                      options={doctorOptions}
                      value={selectedDoctor}
                      onChange={handleDoctorChange}
                      onFocus={handleFocusDoctor}
                    />
                  </div>
                  <div className="col-lg-2 col-12 flex-center">
                    <div
                      className={`button-container-1 border-0 secondarybtn`}
                      data-aos="zoom-in"
                      data-aos-offset="100"
                      data-aos-duration="500"
                      data-aos-once="true"
                      data-aos-easing="ease-in-sine"
                    >
                      <span className="mas">Submit</span>
                      <button type="Submit" name="Hover">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Index;
